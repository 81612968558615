import React, { FC, useEffect, useState } from "react";
import Icon from "@icon-park/react/es/all";
import { format } from "date-fns";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  ErrorContainer,
  FilledButton as Button,
  Text,
  theme,
} from "@ifgengineering/component-library";
import {
  InputsAndButtonContainer,
  InputsContainer,
  TimerContainer,
  TitleSpacer,
  TryADifferentMethodContainer,
} from "./styled";
import {
  BackContainer,
  Cur8OtpInput,
  COUNTDOWN_TIME_IN_SECONDS,
} from "@ifgengineering/client-auth-components";
import { TwoFactorAuthMethod } from "@ifgengineering/client-auth-sdk";
import { authSdk } from "@utils/auth";

interface Cur8StyleOtpFormProps {
  resendOtp: () => void;
  loginURL?: string;
  handleCancel?: () => void;
  handleSubmit: ({
    otp,
  }: {
    otp: string;
    setStatus: (status?: any) => void;
  }) => Promise<void>;
  otpMethod?: TwoFactorAuthMethod;
  submitButtonText?: string;
  errorMessage?: string;
  lastCodeSent?: string | null;
  isLoading?: boolean;
  userEmail: string;
}

let startTime = COUNTDOWN_TIME_IN_SECONDS;
const getFormattedTime = () => {
  const durationAsDate = new Date(startTime * 1000);
  const formattedDuration = format(durationAsDate, "mm:ss");
  return formattedDuration;
};

const Cur8StyleOtpForm: FC<Cur8StyleOtpFormProps> = ({
  handleCancel,
  handleSubmit,
  otpMethod,
  submitButtonText = "Login",
  errorMessage,
  lastCodeSent,
  isLoading,
  userEmail,
}) => {
  const otpFormSchema = Yup.object().shape({
    otp: Yup.string()
      .length(6, "Must be exactly 6 digits")
      .required("Required"),
  });

  const [showResentOtpMessage, setShowResentOtpMessage] = useState(false);
  const [allowSendCode, setAllowSendCode] = useState(false);
  const [formattedTimer, setFormattedTimer] = useState("01:00");
  const [timer, setTimer] = useState<number>();
  const [mfaMethod, setMfaMethod] = useState<TwoFactorAuthMethod>();

  const otherMethod = mfaMethod === "mobile" ? "email" : "mobile";
  const methodLabel = otherMethod === "mobile" ? "SMS" : "email";

  const getTimeDifferenceSinceLastCode = () => {
    if (lastCodeSent) {
      const lastCodeSentDate = new Date(Number(lastCodeSent));
      const now = new Date();
      const diff = now.getTime() - lastCodeSentDate.getTime();
      const secondsPassed = Math.floor(diff / 1000);
      if (secondsPassed < COUNTDOWN_TIME_IN_SECONDS) {
        startTime = -secondsPassed;
      }
    }
  };

  const startTimer = () => {
    // The window is to help TS to understand it will return an number as id
    const timerId = window.setInterval(() => {
      setFormattedTimer(getFormattedTime());
      startTime = startTime - 1;
    }, 1000);

    setTimer(timerId);
  };

  const stopTimer = () => {
    // Same here
    window.clearInterval(timer);
  };

  const clearTimer = () => {
    startTime = COUNTDOWN_TIME_IN_SECONDS;
    setFormattedTimer(getFormattedTime());
  };

  useEffect(() => {
    clearTimer();
    startTimer();
    getTimeDifferenceSinceLastCode();

    return () => {
      clearTimer();
      stopTimer();
    };
  }, []);

  useEffect(() => {
    if (startTime < 0) {
      stopTimer();
      setFormattedTimer("00:00");
      setAllowSendCode(true);
    }
  }, [formattedTimer]);

  useEffect(() => {
    setMfaMethod(otpMethod);
  }, [otpMethod]);

  const handleBackButton = () => {
    stopTimer();
    if (handleCancel) {
      handleCancel();
    }
  };

  const handleSendOtp = async () => {
    if (!allowSendCode) {
      return;
    }

    fadeResentOtpMessage();
    authSdk.generateOtp(userEmail, mfaMethod);
  };

  const handleTryADifferentMethod = async () => {
    if (!mfaMethod) {
      return;
    }

    setMfaMethod(otherMethod);
    fadeResentOtpMessage();
    authSdk.generateOtp(userEmail, otherMethod);
  };

  const fadeResentOtpMessage = () => {
    setShowResentOtpMessage(true);
    stopTimer();
    setTimeout(() => {
      setShowResentOtpMessage(false);
      setAllowSendCode(false);
      clearTimer();
      startTimer();
    }, 3000);
  };

  return (
    <div data-testid="otp">
      {handleCancel && (
        <BackContainer onClick={handleBackButton}>
          <Icon
            type="ArrowLeft"
            size={18}
            theme="outline"
            fill={theme.colors.BLUE600}
          />
          <Text type="S16" color="BLUE600" fontFamily="archialight">
            Back
          </Text>
        </BackContainer>
      )}
      <TitleSpacer>
        <Text type="T32" color="SLATE800" fontFamily="archiasemibold">
          Check your {mfaMethod} for Magic code
        </Text>
      </TitleSpacer>
      <Formik
        initialValues={{
          otp: "",
        }}
        validationSchema={otpFormSchema}
        onSubmit={async (values, { setStatus }) => {
          handleSubmit({ otp: values.otp, setStatus });
        }}
      >
        {({
          errors,
          touched,
          submitForm,
          isSubmitting,
          isValid,
          values,
          setFieldValue,
        }) => {
          return (
            <>
              <InputsAndButtonContainer>
                <div>
                  <Text type="S16" color="SLATE800" fontFamily="Inter">
                    Magic Code
                  </Text>
                  <InputsContainer>
                    <Cur8OtpInput
                      value={values.otp}
                      numInputs={6}
                      onChange={(otp: string) => {
                        setFieldValue("otp", otp);
                      }}
                      hasErrored={Boolean(errorMessage)}
                      isDisabled={isSubmitting}
                    />
                  </InputsContainer>
                  {errorMessage && (
                    <ErrorContainer>
                      <Icon
                        type="Caution"
                        size={14}
                        fill={theme.colors.ERROR800}
                        theme="filled"
                      />
                      <Text type="P14" color="ERROR800">
                        {errorMessage}
                      </Text>
                    </ErrorContainer>
                  )}
                </div>
                {mfaMethod === "email" && (
                  <Text type="P16" color="SLATE800">
                    {"We've sent a code to: "}
                    <Text
                      type="H16"
                      color="SLATE800"
                      as="a"
                      href={`mailto:${userEmail}`}
                    >
                      {userEmail}
                    </Text>
                  </Text>
                )}
                <Button
                  testId="login-button"
                  type="submit"
                  height="56px"
                  text={submitButtonText}
                  color="darkBlue"
                  disabled={
                    (errors.otp && touched.otp) ||
                    !isValid ||
                    !values.otp ||
                    isLoading
                  }
                  isLoading={isLoading}
                  onClick={submitForm}
                />
              </InputsAndButtonContainer>

              <div>
                {showResentOtpMessage ? (
                  <Text type="S16" color="SLATE800" fontFamily="Inter">
                    Magic Code sent!
                  </Text>
                ) : (
                  <>
                    <TimerContainer>
                      <Text type="S16" color="SLATE600" fontFamily="Inter">
                        {formattedTimer}
                      </Text>
                    </TimerContainer>
                    <Text
                      type="S16"
                      color={allowSendCode ? "BLUE600" : "SLATE450"}
                      fontFamily="Inter"
                      style={{ cursor: allowSendCode ? "pointer" : "" }}
                      onClick={handleSendOtp}
                    >
                      Resend Magic Code
                    </Text>
                  </>
                )}
              </div>
              {mfaMethod !== "email" && (
                <TryADifferentMethodContainer>
                  <Text
                    type="S16"
                    fontFamily="Inter"
                    color={"BLUE600"}
                    style={{ cursor: "pointer" }}
                    onClick={handleTryADifferentMethod}
                  >
                    Send magic code via {methodLabel}
                  </Text>
                  <Icon type="RightSmall" fill={theme.colors.BLUE600} />
                </TryADifferentMethodContainer>
              )}
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default Cur8StyleOtpForm;
