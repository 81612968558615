import styled from "styled-components";

export const TitleSpacer = styled.div`
  padding-bottom: 40px;
`;

export const InputsContainer = styled.div`
  padding-top: 5px;
`;

export const InputsAndButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const TimerContainer = styled.div`
  display: inline-block;
  width: 50px;
`;

export const TryADifferentMethodContainer = styled.div`
  display: flex;
  padding-top: 40px;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
`;
