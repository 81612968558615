import React, { FC, useEffect, useState } from "react";
import { navigate } from "gatsby";
import { Text, Card, NavigationBar } from "@ifgengineering/component-library";
import { NavigationBarContainer } from "../navbar/styled";
import PageContainer from "../pageContainer/PageContainer";
import {
  CardContainer,
  ContentContainer,
  CtaContainer,
  TwoFAPageContainer,
} from "./styled";
import Cur8StyleOtpForm from "../otp/Cur8StyleOtpForm";
import { authSdk } from "@utils/auth";
import { logException } from "@helpers/logException/logException";
import { FilledButton } from "@ifgengineering/component-library";
import { TwoFactorAuthMethod } from "@ifgengineering/client-auth-sdk";

type Props = {
  email?: string;
  primaryTwoFactorAuthMethod?: string;
};

const MFAComponent: FC<Props> = ({ email, primaryTwoFactorAuthMethod }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const methodText: TwoFactorAuthMethod =
    primaryTwoFactorAuthMethod === "email"
      ? primaryTwoFactorAuthMethod
      : "mobile";
  const lastCodeSent = localStorage.getItem("mfa_timestamp");

  const checkIfMinutePassed = () => {
    // If the user has requested a verification code less than a minute ago,
    // they will not see the button to request another, even if they refresh.
    if (lastCodeSent) {
      const lastCodeSentDate = new Date(Number(lastCodeSent));
      const now = new Date();
      const diff = now.getTime() - lastCodeSentDate.getTime();
      const minutes = Math.floor(diff / 1000 / 60);
      return minutes > 0;
    }
    return true;
  };

  useEffect(() => {
    if (!checkIfMinutePassed()) setCodeSent(true);
  }, []);

  const generateOtp = async () => {
    try {
      await authSdk.generateOtp(email as string);
      setCodeSent(true);
      localStorage.setItem("mfa_timestamp", `${new Date().getTime()}`);
    } catch (e) {
      logException({
        scopeEmail: email,
        tag: "generateOtp",
        exception: e,
      });
    }
  };

  const handleOtpSubmit = async ({ otp }: { otp: string }) => {
    try {
      await authSdk.verifyOTP(otp);

      navigate("/app/deals");
    } catch (e: any) {
      if (e.response.status === 401) {
        setErrorMessage("The code you provided seems invalid or expired");
      } else {
        logException({
          scopeEmail: email,
          tag: "verifyOTP",
          exception: e,
        });
      }
    }
  };

  const renderCallToAction = () => (
    <CtaContainer>
      <Text type="T24" color="SLATE800" fontFamily="archiasemibold">
        Your account has 2FA enabled
      </Text>
      <FilledButton
        height="56px"
        color="blue"
        text="Get verification code"
        onClick={generateOtp}
      />
    </CtaContainer>
  );

  return (
    <PageContainer>
      <TwoFAPageContainer>
        <NavigationBarContainer>
          <NavigationBar
            navItemsTextColor="WHITE"
            selectedNavItemsTextColor="WHITE"
            bgColor="BLACK"
            selectedNavItemsBgColor="BLUE600"
            navItems={[]}
            pageName="Invest"
          />
        </NavigationBarContainer>
        <ContentContainer>
          <Text color="SLATE900" type="T32" fontFamily="archiasemibold">
            2FA Verification
          </Text>
          <Text type="S20" color="SLATE700">
            Before you invest, please verify it’s you
          </Text>
          {email && (
            <CardContainer>
              <Card>
                {!codeSent ? (
                  renderCallToAction()
                ) : (
                  <Cur8StyleOtpForm
                    lastCodeSent={lastCodeSent}
                    resendOtp={generateOtp}
                    handleSubmit={handleOtpSubmit}
                    otpMethod={methodText}
                    submitButtonText="Submit"
                    errorMessage={errorMessage}
                  />
                )}
              </Card>
            </CardContainer>
          )}
        </ContentContainer>
      </TwoFAPageContainer>
    </PageContainer>
  );
};

export default MFAComponent;
