import { Loading } from "@components/Loading/Loading.component";
import { navigate } from "gatsby";
import React, { FC, useEffect, useState } from "react";
import MFAComponent from "../components/MFAComponent";
import { authSdk } from "../utils/auth";
import { logException } from "@helpers/logException/logException";

const MFAPage: FC = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [authMethod, setAuthMethod] = useState("");

  useEffect(() => {
    const loadUser = async () => {
      setLoading(true);
      try {
        await authSdk.userInfo();
      } catch (e: any) {
        const user = e.response.data.user;
        if (e.response.status !== 403) {
          navigate("/app/login");

          logException({
            scopeEmail: email,
            tag: "userInfo",
            exception: e,
          });
        } else {
          setLoading(false);
          setEmail(user.email);
          setAuthMethod(user.primaryTwoFactorAuthMethod);
        }
      }
    };

    loadUser();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return <MFAComponent email={email} primaryTwoFactorAuthMethod={authMethod} />;
};

export default MFAPage;
