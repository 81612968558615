import styled from "styled-components";
import { theme } from "@ifgengineering/component-library";

export const TwoFAPageContainer = styled.div`
  background-color: ${theme.colors.SLATE200};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  margin: 40px auto;
  max-width: 366px;
  text-align: center;
`;

export const CardContainer = styled.div`
  margin-top: 32px;
  text-align: left;
`;

export const CtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
